var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.sidebarClasses(), attrs: { id: "sidebar" } },
    [
      _c(
        "a",
        {
          staticClass: "toggle-close",
          class: _vm.channel.skin_block_background_color
            ? "bg-" + _vm.channel.skin_block_background_color
            : "bg-light",
          attrs: { href: "#" },
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.toggleSidebar(false)
            },
          },
        },
        [
          _c("span", {
            class: _vm.channel.skin_block_text_color
              ? "bg-" + _vm.channel.skin_block_text_color
              : "bg-dark",
          }),
          _c("span", {
            class: _vm.channel.skin_block_text_color
              ? "bg-" + _vm.channel.skin_block_text_color
              : "bg-dark",
          }),
          _c("span", {
            class: _vm.channel.skin_block_text_color
              ? "bg-" + _vm.channel.skin_block_text_color
              : "bg-dark",
          }),
        ]
      ),
      _c(
        "div",
        { staticClass: "d-md-none", attrs: { id: "tabs" } },
        [
          _c(
            "b-row",
            {
              class: _vm.channel.skin_block_text_color
                ? "text-" + _vm.channel.skin_block_text_color
                : "text-dark",
            },
            [
              _vm.asset.asset_qa &&
              _vm.asset.asset_qa.enabled &&
              _vm.asset.asset_qa.sidebar
                ? _c("b-col", { staticClass: "text-center px-0" }, [
                    _c(
                      "a",
                      {
                        staticClass: "small tab border-bottom",
                        class: _vm.sidebar === "qa" ? "active" : null,
                        attrs: {
                          href: "#",
                          title: _vm.$t("common.ask-question"),
                        },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.toggleSidebar("qa")
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("common.ask")) + " ")]
                    ),
                  ])
                : _vm._e(),
              _vm.asset.chat_url
                ? _c("b-col", { staticClass: "text-center px-0" }, [
                    _c(
                      "a",
                      {
                        staticClass: "small tab border-bottom",
                        class: _vm.sidebar === "chat" ? "active" : null,
                        attrs: { href: "#", title: _vm.$t("common.chat") },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.toggleSidebar("chat")
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("common.chat")) + " ")]
                    ),
                  ])
                : _vm._e(),
              _vm.asset.poll_url
                ? _c("b-col", { staticClass: "text-center px-0" }, [
                    _c(
                      "a",
                      {
                        staticClass: "small tab border-bottom",
                        class: _vm.sidebar === "poll" ? "active" : null,
                        attrs: { href: "#", title: _vm.$t("common.poll") },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.toggleSidebar("poll")
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("common.poll")) + " ")]
                    ),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm.asset.asset_qa &&
      _vm.asset.asset_qa.enabled &&
      _vm.asset.asset_qa.sidebar &&
      _vm.sidebar === "qa"
        ? _c("Ask")
        : _vm._e(),
      _vm.asset.chat_url && _vm.sidebar === "chat" ? _c("Chat") : _vm._e(),
      _vm.asset.poll_url && _vm.sidebar === "poll" ? _c("Poll") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }