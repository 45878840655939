var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.questions
    ? _c(
        "div",
        { staticClass: "py-1 py-md-3", attrs: { id: "presenter" } },
        [
          _c("b-container", { staticClass: "p-0" }, [
            _c(
              "div",
              { staticClass: "table-container p-2 p-md-3" },
              [
                _c(
                  "b-table-simple",
                  { attrs: { small: "", responsive: "sm" } },
                  [
                    _c(
                      "b-tbody",
                      [
                        _c(
                          "Draggable",
                          {
                            attrs: { draggable: ".item", handle: ".order" },
                            on: { update: _vm.changeOrder },
                            model: {
                              value: _vm.questions,
                              callback: function ($$v) {
                                _vm.questions = $$v
                              },
                              expression: "questions",
                            },
                          },
                          _vm._l(_vm.questions, function (item) {
                            return _c(
                              "b-tr",
                              { key: item.id, staticClass: "item" },
                              [
                                _c(
                                  "b-td",
                                  {
                                    staticClass:
                                      "col-auto text-dark bg-gray-lighter px-3 d-none d-md-table-cell",
                                  },
                                  [
                                    _c("p", { staticClass: "m-0 number" }, [
                                      _vm._v(_vm._s(item.id)),
                                    ]),
                                  ]
                                ),
                                _c("b-td", { staticClass: "col" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "text pl-4",
                                      on: {
                                        click: function ($event) {
                                          _vm.showMore !== item.id
                                            ? (_vm.showMore = item.id)
                                            : (_vm.showMore = null)
                                        },
                                      },
                                    },
                                    [
                                      _c("p", { staticClass: "m-0" }, [
                                        _vm._v(_vm._s(item.text)),
                                      ]),
                                      _vm.showMore === item.id
                                        ? _c("div", [
                                            _vm.asset.asset_qa.name && item.name
                                              ? _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "m-0 pt-2 small font-italic",
                                                  },
                                                  [
                                                    _c("font-awesome-icon", {
                                                      staticClass: "mr-1",
                                                      attrs: {
                                                        icon: ["fas", "user"],
                                                        "fixed-width": "",
                                                      },
                                                    }),
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(item.name) +
                                                        " "
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _vm.asset.asset_qa.title &&
                                            item.title
                                              ? _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "m-0 pt-2 small font-italic",
                                                  },
                                                  [
                                                    _c("font-awesome-icon", {
                                                      staticClass: "mr-1",
                                                      attrs: {
                                                        icon: ["fas", "pen"],
                                                        "fixed-width": "",
                                                      },
                                                    }),
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(item.title) +
                                                        " "
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _vm.asset.asset_qa.phone &&
                                            item.phone
                                              ? _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "m-0 pt-2 small font-italic",
                                                  },
                                                  [
                                                    _c("font-awesome-icon", {
                                                      staticClass: "mr-1",
                                                      attrs: {
                                                        icon: ["fas", "phone"],
                                                        "fixed-width": "",
                                                      },
                                                    }),
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(item.phone) +
                                                        " "
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _vm.asset.asset_qa.company &&
                                            item.company
                                              ? _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "m-0 pt-2 small font-italic",
                                                  },
                                                  [
                                                    _c("font-awesome-icon", {
                                                      staticClass: "mr-1",
                                                      attrs: {
                                                        icon: [
                                                          "fas",
                                                          "building",
                                                        ],
                                                        "fixed-width": "",
                                                      },
                                                    }),
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(item.company) +
                                                        " "
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _vm.asset.asset_qa.email &&
                                            item.email
                                              ? _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "m-0 pt-2 small font-italic",
                                                  },
                                                  [
                                                    _c("font-awesome-icon", {
                                                      staticClass: "mr-1",
                                                      attrs: {
                                                        icon: [
                                                          "fas",
                                                          "envelope",
                                                        ],
                                                        "fixed-width": "",
                                                      },
                                                    }),
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(item.email) +
                                                        " "
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "m-0 pt-2 small font-italic",
                                              },
                                              [
                                                _c("font-awesome-icon", {
                                                  staticClass: "mr-1",
                                                  attrs: {
                                                    icon: ["fas", "calendar"],
                                                    "fixed-width": "",
                                                  },
                                                }),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.humanizeDate(
                                                        item.created_at
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ],
                                              1
                                            ),
                                          ])
                                        : item.email
                                        ? _c(
                                            "p",
                                            {
                                              staticClass:
                                                "m-0 pt-2 small font-italic",
                                            },
                                            [_vm._v(_vm._s(item.email))]
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                ]),
                                _c(
                                  "b-td",
                                  { staticClass: "col-auto" },
                                  [
                                    _c(
                                      "b-btn",
                                      {
                                        staticClass: "hover-success",
                                        attrs: { variant: "link" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.editQuestion(
                                              "answered",
                                              item
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm.loading &&
                                        _vm.loading.type === "answered" &&
                                        _vm.loading.id === item.id
                                          ? _c("font-awesome-icon", {
                                              attrs: {
                                                icon: ["fas", "spinner"],
                                                spin: "",
                                              },
                                            })
                                          : _c("font-awesome-icon", {
                                              attrs: {
                                                icon: [
                                                  "fas",
                                                  item.answered
                                                    ? "times"
                                                    : "check",
                                                ],
                                              },
                                            }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-td",
                                  {
                                    staticClass:
                                      "col-auto text-dark bg-gray-lighter px-4 order",
                                  },
                                  [
                                    _c(
                                      "p",
                                      { staticClass: "m-0" },
                                      [
                                        _c("font-awesome-icon", {
                                          attrs: { icon: ["fas", "bars"] },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        1
      )
    : _c("Loading", { attrs: { inline: true } })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }