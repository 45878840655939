var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.questionsFiltered
    ? _c(
        "div",
        { staticClass: "py-1 py-md-3", attrs: { id: "moderator" } },
        [
          _vm._l(_vm.questionsFiltered, function (questions, type, key) {
            return _c("b-container", { key: key, staticClass: "p-0" }, [
              questions.length
                ? _c(
                    "div",
                    { staticClass: "table-container p-2 p-md-3" },
                    [
                      _c(
                        "b-table-simple",
                        { attrs: { small: "", responsive: "sm" } },
                        [
                          _c(
                            "b-tbody",
                            [
                              _c(
                                "Draggable",
                                {
                                  attrs: {
                                    draggable: ".item",
                                    handle: ".order",
                                  },
                                  on: { update: _vm.changeOrder },
                                  model: {
                                    value: _vm.questionsFiltered[type],
                                    callback: function ($$v) {
                                      _vm.$set(_vm.questionsFiltered, type, $$v)
                                    },
                                    expression: "questionsFiltered[type]",
                                  },
                                },
                                _vm._l(questions, function (item) {
                                  return _c(
                                    "b-tr",
                                    {
                                      key: item.id,
                                      staticClass: "item",
                                      class: [
                                        _vm.rowClass(item),
                                        _vm.showMore === item.id
                                          ? "active"
                                          : null,
                                      ],
                                    },
                                    [
                                      _c(
                                        "b-td",
                                        {
                                          staticClass:
                                            "col-auto text-dark bg-gray-lighter px-3 d-none d-md-table-cell",
                                        },
                                        [
                                          _c(
                                            "p",
                                            { staticClass: "m-0 number" },
                                            [_vm._v(_vm._s(item.id))]
                                          ),
                                        ]
                                      ),
                                      _c("b-td", { staticClass: "col" }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "text pl-4",
                                            on: {
                                              click: function ($event) {
                                                _vm.showMore !== item.id
                                                  ? (_vm.showMore = item.id)
                                                  : (_vm.showMore = null)
                                              },
                                            },
                                          },
                                          [
                                            _c("p", { staticClass: "m-0" }, [
                                              _vm._v(_vm._s(item.text)),
                                            ]),
                                            _vm.showMore === item.id
                                              ? _c("div", [
                                                  _vm.asset.asset_qa.name &&
                                                  item.name
                                                    ? _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "m-0 pt-2 small font-italic",
                                                        },
                                                        [
                                                          _c(
                                                            "font-awesome-icon",
                                                            {
                                                              staticClass:
                                                                "mr-1",
                                                              attrs: {
                                                                icon: [
                                                                  "fas",
                                                                  "user",
                                                                ],
                                                                "fixed-width":
                                                                  "",
                                                              },
                                                            }
                                                          ),
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                item.name
                                                              ) +
                                                              " "
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _vm.asset.asset_qa.title &&
                                                  item.title
                                                    ? _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "m-0 pt-2 small font-italic",
                                                        },
                                                        [
                                                          _c(
                                                            "font-awesome-icon",
                                                            {
                                                              staticClass:
                                                                "mr-1",
                                                              attrs: {
                                                                icon: [
                                                                  "fas",
                                                                  "pen",
                                                                ],
                                                                "fixed-width":
                                                                  "",
                                                              },
                                                            }
                                                          ),
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                item.title
                                                              ) +
                                                              " "
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _vm.asset.asset_qa.phone &&
                                                  item.phone
                                                    ? _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "m-0 pt-2 small font-italic",
                                                        },
                                                        [
                                                          _c(
                                                            "font-awesome-icon",
                                                            {
                                                              staticClass:
                                                                "mr-1",
                                                              attrs: {
                                                                icon: [
                                                                  "fas",
                                                                  "phone",
                                                                ],
                                                                "fixed-width":
                                                                  "",
                                                              },
                                                            }
                                                          ),
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                item.phone
                                                              ) +
                                                              " "
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _vm.asset.asset_qa.company &&
                                                  item.company
                                                    ? _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "m-0 pt-2 small font-italic",
                                                        },
                                                        [
                                                          _c(
                                                            "font-awesome-icon",
                                                            {
                                                              staticClass:
                                                                "mr-1",
                                                              attrs: {
                                                                icon: [
                                                                  "fas",
                                                                  "building",
                                                                ],
                                                                "fixed-width":
                                                                  "",
                                                              },
                                                            }
                                                          ),
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                item.company
                                                              ) +
                                                              " "
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _vm.asset.asset_qa.email &&
                                                  item.email
                                                    ? _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "m-0 pt-2 small font-italic",
                                                        },
                                                        [
                                                          _c(
                                                            "font-awesome-icon",
                                                            {
                                                              staticClass:
                                                                "mr-1",
                                                              attrs: {
                                                                icon: [
                                                                  "fas",
                                                                  "envelope",
                                                                ],
                                                                "fixed-width":
                                                                  "",
                                                              },
                                                            }
                                                          ),
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                item.email
                                                              ) +
                                                              " "
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "m-0 pt-2 small font-italic",
                                                    },
                                                    [
                                                      _c("font-awesome-icon", {
                                                        staticClass: "mr-1",
                                                        attrs: {
                                                          icon: [
                                                            "fas",
                                                            "calendar",
                                                          ],
                                                          "fixed-width": "",
                                                        },
                                                      }),
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.humanizeDate(
                                                              item.created_at
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ])
                                              : item.email
                                              ? _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "m-0 pt-2 small font-italic",
                                                  },
                                                  [_vm._v(_vm._s(item.email))]
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                      ]),
                                      _c(
                                        "b-td",
                                        {
                                          staticClass:
                                            "col-auto d-none d-md-table-cell",
                                        },
                                        [
                                          _c(
                                            "b-btn",
                                            {
                                              staticClass: "hover-success",
                                              attrs: { variant: "link" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.editQuestion(
                                                    "answered",
                                                    item
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm.loading &&
                                              _vm.loading.type === "answered" &&
                                              _vm.loading.id === item.id
                                                ? _c("font-awesome-icon", {
                                                    attrs: {
                                                      icon: ["fas", "spinner"],
                                                      spin: "",
                                                    },
                                                  })
                                                : _c("font-awesome-icon", {
                                                    attrs: {
                                                      icon: [
                                                        "fas",
                                                        item.answered
                                                          ? "times"
                                                          : "check",
                                                      ],
                                                    },
                                                  }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-td",
                                        {
                                          staticClass:
                                            "col-auto d-none d-md-table-cell",
                                        },
                                        [
                                          _c(
                                            "b-btn",
                                            {
                                              staticClass: "hover-secondary",
                                              attrs: { variant: "link" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.editQuestion(
                                                    "rejected",
                                                    item
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm.loading &&
                                              _vm.loading.type === "rejected" &&
                                              _vm.loading.id === item.id
                                                ? _c("font-awesome-icon", {
                                                    attrs: {
                                                      icon: ["fas", "spinner"],
                                                      spin: "",
                                                    },
                                                  })
                                                : _c("font-awesome-icon", {
                                                    attrs: {
                                                      icon: [
                                                        "fas",
                                                        item.rejected
                                                          ? "eye-slash"
                                                          : "eye",
                                                      ],
                                                    },
                                                  }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-td",
                                        {
                                          staticClass:
                                            "col-auto d-none d-md-table-cell",
                                        },
                                        [
                                          _c(
                                            "b-btn",
                                            {
                                              staticClass: "hover-danger",
                                              attrs: {
                                                variant: "link",
                                                disabled: _vm.loading
                                                  ? true
                                                  : false,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.deleteQuestion(
                                                    item
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm.loading &&
                                              _vm.loading.type === "delete" &&
                                              _vm.loading.id === item.id
                                                ? _c("font-awesome-icon", {
                                                    attrs: {
                                                      icon: ["fas", "spinner"],
                                                      spin: "",
                                                    },
                                                  })
                                                : _c("font-awesome-icon", {
                                                    attrs: {
                                                      icon: ["fas", "trash"],
                                                    },
                                                  }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-td",
                                        { staticClass: "col-auto d-md-none" },
                                        [
                                          _c(
                                            "b-dropdown",
                                            {
                                              staticClass: "p-0",
                                              attrs: {
                                                variant: "link",
                                                dropleft: "",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "button-content",
                                                    fn: function () {
                                                      return [
                                                        _vm.loading &&
                                                        _vm.loading.id ===
                                                          item.id
                                                          ? _c(
                                                              "font-awesome-icon",
                                                              {
                                                                attrs: {
                                                                  icon: [
                                                                    "fas",
                                                                    "spinner",
                                                                  ],
                                                                  spin: "",
                                                                },
                                                              }
                                                            )
                                                          : _c(
                                                              "font-awesome-icon",
                                                              {
                                                                attrs: {
                                                                  icon: [
                                                                    "fas",
                                                                    "cog",
                                                                  ],
                                                                },
                                                              }
                                                            ),
                                                      ]
                                                    },
                                                    proxy: true,
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            },
                                            [
                                              _c(
                                                "b-dropdown-item",
                                                [
                                                  _c(
                                                    "b-btn",
                                                    {
                                                      attrs: {
                                                        variant: "link",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.editQuestion(
                                                            "answered",
                                                            item
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("font-awesome-icon", {
                                                        attrs: {
                                                          icon: [
                                                            "fas",
                                                            item.answered
                                                              ? "times"
                                                              : "check",
                                                          ],
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "b-dropdown-item",
                                                [
                                                  _c(
                                                    "b-btn",
                                                    {
                                                      attrs: {
                                                        variant: "link",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.editQuestion(
                                                            "rejected",
                                                            item
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("font-awesome-icon", {
                                                        attrs: {
                                                          icon: [
                                                            "fas",
                                                            item.rejected
                                                              ? "eye-slash"
                                                              : "eye",
                                                          ],
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "b-dropdown-item",
                                                [
                                                  _c(
                                                    "b-btn",
                                                    {
                                                      attrs: {
                                                        variant: "link",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.deleteQuestion(
                                                            item
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("font-awesome-icon", {
                                                        attrs: {
                                                          icon: [
                                                            "fas",
                                                            "trash",
                                                          ],
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-td",
                                        {
                                          staticClass:
                                            "col-auto text-dark bg-gray-lighter px-4 order cursor-pointer",
                                        },
                                        [
                                          _c(
                                            "p",
                                            { staticClass: "m-0" },
                                            [
                                              _c("font-awesome-icon", {
                                                attrs: {
                                                  icon: ["fas", "bars"],
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ])
          }),
          _c(
            "b-modal",
            {
              ref: "addQuestionModal",
              attrs: {
                id: "addQuestionModal",
                "hide-footer": "",
                "hide-header": "",
                centered: "",
              },
            },
            [
              _c(
                "div",
                { staticClass: "content p-4", class: _vm.blockClasses() },
                [
                  _c("h6", { staticClass: "pb-3" }, [
                    _vm._v(_vm._s(_vm.$t("questions.add-question"))),
                  ]),
                  _c(
                    "b-form",
                    {
                      on: {
                        submit: function ($event) {
                          $event.preventDefault()
                          return _vm.addQuestion()
                        },
                      },
                    },
                    [
                      _vm.asset.asset_qa.name
                        ? _c(
                            "b-form-group",
                            [
                              _c("b-form-input", {
                                class: _vm.inputClasses(),
                                attrs: {
                                  id: "questionName",
                                  type: "text",
                                  placeholder:
                                    _vm.$t("form.name") +
                                    (_vm.asset.asset_qa.name === 2 ? " *" : ""),
                                  required:
                                    _vm.asset.asset_qa.name === 2
                                      ? true
                                      : false,
                                },
                                model: {
                                  value: _vm.questionForm.name,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.questionForm, "name", $$v)
                                  },
                                  expression: "questionForm.name",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.asset.asset_qa.title
                        ? _c(
                            "b-form-group",
                            [
                              _c("b-form-input", {
                                class: _vm.inputClasses(),
                                attrs: {
                                  id: "questionTitle",
                                  type: "text",
                                  placeholder:
                                    _vm.$t("form.title") +
                                    (_vm.asset.asset_qa.title === 2
                                      ? " *"
                                      : ""),
                                  required:
                                    _vm.asset.asset_qa.title === 2
                                      ? true
                                      : false,
                                },
                                model: {
                                  value: _vm.questionForm.title,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.questionForm, "title", $$v)
                                  },
                                  expression: "questionForm.title",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.asset.asset_qa.phone
                        ? _c(
                            "b-form-group",
                            [
                              _c("b-form-input", {
                                class: _vm.inputClasses(),
                                attrs: {
                                  id: "questionPhone",
                                  type: "tel",
                                  placeholder:
                                    _vm.$t("form.phone") +
                                    (_vm.asset.asset_qa.phone === 2
                                      ? " *"
                                      : ""),
                                  required:
                                    _vm.asset.asset_qa.phone === 2
                                      ? true
                                      : false,
                                },
                                model: {
                                  value: _vm.questionForm.phone,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.questionForm, "phone", $$v)
                                  },
                                  expression: "questionForm.phone",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.asset.asset_qa.company
                        ? _c(
                            "b-form-group",
                            [
                              _c("b-form-input", {
                                class: _vm.inputClasses(),
                                attrs: {
                                  id: "questionCompany",
                                  type: "text",
                                  placeholder:
                                    _vm.$t("form.company") +
                                    (_vm.asset.asset_qa.company === 2
                                      ? " *"
                                      : ""),
                                  required:
                                    _vm.asset.asset_qa.company === 2
                                      ? true
                                      : false,
                                },
                                model: {
                                  value: _vm.questionForm.company,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.questionForm, "company", $$v)
                                  },
                                  expression: "questionForm.company",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.asset.asset_qa.email
                        ? _c(
                            "b-form-group",
                            [
                              _c("b-form-input", {
                                class: _vm.inputClasses(),
                                attrs: {
                                  id: "questionEmail",
                                  type: "email",
                                  placeholder:
                                    _vm.$t("form.email") +
                                    (_vm.asset.asset_qa.email === 2
                                      ? " *"
                                      : ""),
                                  required:
                                    _vm.asset.asset_qa.email === 2
                                      ? true
                                      : false,
                                },
                                model: {
                                  value: _vm.questionForm.email,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.questionForm, "email", $$v)
                                  },
                                  expression: "questionForm.email",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "b-form-group",
                        { staticClass: "textarea" },
                        [
                          _c("b-form-textarea", {
                            class: _vm.inputClasses(),
                            attrs: {
                              id: "questionText",
                              placeholder: _vm.$t("form.question"),
                              rows: "3",
                              "max-rows": "6",
                              required: "",
                            },
                            model: {
                              value: _vm.questionForm.text,
                              callback: function ($$v) {
                                _vm.$set(_vm.questionForm, "text", $$v)
                              },
                              expression: "questionForm.text",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-btn",
                        {
                          class: _vm.asset.theme.skin_button_text_color
                            ? "text-" + _vm.asset.theme.skin_button_text_color
                            : "text-light",
                          attrs: {
                            type: "submit",
                            variant: _vm.asset.theme.skin_button_color
                              ? _vm.asset.theme.skin_button_color
                              : "dark",
                            block: "",
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("form.send")) + " ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        2
      )
    : _c("Loading", { attrs: { inline: true } })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }