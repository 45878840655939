<template>
	<div id="downloads" class="accordion" role="tablist">
		<b-card class="mb-7" :class="blockClasses()" no-body v-if="asset.downloadable === 1 || filteredFormats().length || filteredAssets().length">
			<b-card-header header-tag="div" role="tab">
				<b-row align-v="center">
					<b-col cols="auto">
						<p class="m-0 lead">{{ $t('downloads.title') }}</p>
					</b-col>
					<b-col cols="auto" class="ml-auto" v-if="downloading">
						<p class="m-0 small">{{ downloading+'%' }}</p>
					</b-col>
				</b-row>
			</b-card-header>
			<b-collapse id="downloadsList" role="tabpanel" visible>
				<b-list-group v-if="asset.downloadable">
					<b-list-group-item 	@click="downloadFromUrl(asset.original_format.download_url, asset.title + '.' + asset.original_format.format.container)"
										v-if="asset.downloadable === 1">
						<b-btn block variant="link">
							<font-awesome-icon :icon="['fas', 'caret-down']" />
							<span class="small">{{ asset.title + '.' + asset.original_format.format.container }}</span> <span class="small text-lowercase">({{ $t('downloads.original') }})</span>
						</b-btn>
					</b-list-group-item>
				</b-list-group>
				<b-list-group v-if="filteredFormats().length">
					<b-list-group-item 	@click="downloadFormat(format)"
										:key="index"
										v-for="(format, index) in filteredFormats()">
						<b-btn block variant="link">
							<font-awesome-icon :icon="['fas', 'caret-down']" />
							<span class="small">{{ asset.title + '.' + format.format.container }}</span> <span class="small">({{ format.format.name }})</span>
						</b-btn>
					</b-list-group-item>
				</b-list-group>
				<b-list-group v-if="filteredAssets().length">
					<b-list-group-item 	@click="downloadFile(asset)"
										:key="index"
										v-for="(asset, index) in filteredAssets()">
						<b-btn block variant="link">
							<font-awesome-icon :icon="['fas', 'caret-down']" />
							<span class="small">{{ asset.title }}</span> <span class="small">({{ asset.type === 1 ? asset.download_formats[0].name : asset.file_ext }})</span>
						</b-btn>
					</b-list-group-item>
				</b-list-group>
			</b-collapse>
		</b-card>
	</div>
</template>
<script>
	export default {
		computed: {
			asset() {
				return this.$store.getters.asset
			},
			assets() {
				return this.$store.getters.assets
			},
			downloading() {
				return this.$store.getters.downloading
			}
		},
		methods: {
			filteredFormats() {
				return this.asset.asset_format.filter(f => f.format.type === 'download')
			},
			filteredAssets() {
				let assetsArray = []
				this.assets.forEach((a) => {
					if(a.id !== this.asset.id && (a.type !== 1 || a.download_formats && a.download_formats.length)) {
						assetsArray.push(a)
					}
				})
				return assetsArray
			},
			downloadFormat(format) {
				const data = {
					type: 'video',
					slug: this.asset.asset_category_key,
					hash: format.hash,
					token: this.$route.query.token,
					password: this.$route.query.password
				}
				this.$store.dispatch('download', data)
				.then(response => {
					const name = this.asset.title + '.' + format.format.container
					this.downloadFromUrl(response.data.url, name)
				})
				.catch(() => {
					this.$toasted.error(this.$t('downloads.error'), { position: 'bottom-right' })
				})
			},
			downloadFile(asset) {
				const data = {
					type: this.getType(asset.type),
					slug: asset.asset_category_key,
					hash: asset.download_formats && asset.download_formats.length ? asset.download_formats[0].hash : null,
					token: this.$route.query.token,
					password: this.$route.query.password
				}
				this.$store.dispatch('download', data)
				.then(response => {
					const name = asset.title + '.' + asset.file_ext
					this.downloadFromUrl(response.data.url, name)
				})
				.catch(() => {
					this.$toasted.error(this.$t('downloads.error'), { position: 'bottom-right' })
				})
			},
			downloadFromUrl(url, name) {
				this.$store.dispatch('downloadFromUrl', { url: url })
				.then(response => {
					const url = window.URL.createObjectURL(new Blob([response.data]))
					const link = document.createElement('a')
					link.href = url
					link.setAttribute('download', name)
					link.click()
				})
				.catch(() => {
					this.$toasted.error(this.$t('downloads.error'), { position: 'bottom-right' })
				})
			},
			getType(type) {
				if(type === 1) return 'video'
				return 'file'
			},
			blockClasses() {
				return [
					this.asset && this.asset.theme && this.asset.theme.skin_block_background_color ? 'bg-'+this.asset.theme.skin_block_background_color : 'bg-dark',
					this.asset && this.asset.theme && this.asset.theme.skin_block_text_color ? 'text-'+this.asset.theme.skin_block_text_color : 'text-light'
				]
			}
		}
	}
</script>
