<template>
	<div id="postlive" class="h-100-header">
		<b-container fluid class="h-100  px-0 pl-3">
			<b-row class="h-100">
				<b-col cols="12" xl="4" class="h-50-pre">
					<b-row class="pl-1 pl-lg-7 h-100 pb-3 pt-4 pt-xl-0 d-flex justify-content-start">
						<b-col cols="12" class="py-3 ">
							<h2 v-if="customField('label')" v-html="makeBr(customField('label'))"></h2>
							<h1 class="pt-01" v-html="makeBr(asset.title)" />
						</b-col>
						<b-col 	cols="12" offset="0" md="8" xl="12" offset-md="4" offset-xl="0"
								class="text-dark pl-5 pr-8 d-flex flex-wrap align-content-start align-content-md-end align-content-xl-start">
							<div class="pb-5 pt-5 w-100">
								<p class="big m-0 pt-3" v-html="makeBr(asset.postlive)" v-if="asset.postlive"></p>
								<p class="big m-0 pt-3" v-html="makeBr(asset.description)" v-else-if="asset.description"></p>
							</div>
						</b-col>
					</b-row>
				</b-col>
				<b-col cols="4" :style="backgroundImage() ? backgroundImage() : null"  class="bg-skanska-cover"></b-col>
				<b-col cols="4" class="bg-skanska-a"></b-col>
			</b-row>
		</b-container>
	</div>
</template>
<script>
	export default {
		computed: {
			asset() {
				return this.$store.getters.asset
			}
		},
		components: {
		},
		methods: {
			postliveQa() {
				if(this.asset.asset_qa && this.asset.asset_qa.enabled && this.asset.asset_qa.postlive) return true
				return false
			},
			makeBr(text) {
				return text.replace('\n', '<br>')
			},
			backgroundImage() {
				const background = this.asset && this.asset.background && this.asset.background.type === "image" && this.asset.background.url
				if (background) return 'background-image: url(' + this.asset.background.url + ')'
				if (this.asset && this.asset.poster) return 'background-image: url(' + this.asset.poster + ')'
				return null
			},
			customField(slug) {
				var value = null
				if (this.asset.customfieldgroups) {
					_.forEach(this.asset.customfieldgroups, group => {
						if (_.find(group.customfields, { slug: slug })) {
							value = _.find(group.customfields, { slug: slug }) ? _.find(group.customfields, { slug: slug }).value : false;
						}
					})
				}
				return value
			},
		}
	}
</script>
