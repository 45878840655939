<template>
	<div id="prelive" class="h-100-header">
		<b-container fluid class="h-100  px-0 pl-3">
			<b-row class="h-100">
				<b-col cols="12" xl="4" class="h-50-pre">
					<b-row  class="pl-1 pl-lg-7 h-100 pb-3 pt-4 pt-xl-0 d-flex justify-content-start">
						<b-col cols="12" class="py-3 ">
							<h2 v-if="customField('label')" v-html="makeBr(customField('label'))"></h2>
							<h1 class="pt-01" v-html="makeBr(asset.title)"/>
						</b-col>
						<b-col cols="12" offset="0" md="8" xl="12" offset-md="4" offset-xl="0" class="text-dark pl-5 pr-8 d-flex flex-wrap align-content-start align-content-md-end align-content-xl-start">
							<div class="pb-5 pt-5 w-100">
							<p class="big m-0" v-if="asset.is_countdown && starting">{{ $t('countdown.event') }}...</p>
							<p class="big m-0" v-html="makeBr(asset.prelive)" v-else-if="asset.prelive"/>
							<p class="big m-0" v-html="makeBr(asset.description)" v-else-if="asset.description"/>
							<countdown @cdStarting="cdStarts" class="mt-3 mt-xl-6 py-4 border-countdown px-xl-0" v-if="asset.is_countdown"/>
							</div>
						</b-col>
					</b-row>
				</b-col>
				<b-col cols="6" xl="4" :style="backgroundImage() ? backgroundImage() : null" class="bg-skanska-cover"></b-col>
				<b-col cols="6" xl="4" :class="customField(upload_on) && customField(upload_on).length !== 0  ? '' : 'bg-skanska-k'">
					<upload v-if="customField(upload_on) && customField(upload_on).length !== 0"/>
				</b-col>
			</b-row>
		</b-container>
	</div>
</template>
<script>
	import Countdown from '@/components/asset/Countdown.vue'
	import Upload from '@/components/asset/Upload.vue'
	export default {
		data() {
			return {
				upload_on: process.env.VUE_APP_UPLOADED_UPLOAD,
				starting: false,
			}
		},
		computed: {
			asset() {
				return this.$store.getters.asset
			},
			type() {
				return this.$store.getters.type
			}
		},
		components: {
			Countdown,
			Upload
		},
		methods: {
			preliveQa() {
				if(this.asset.asset_qa && this.asset.asset_qa.enabled && this.asset.asset_qa.prelive) return true
				return false
			},
			makeBr(text) {
				return text.replace(/\n/g, '<br>')
			},
			cdStarts(val) {
				this.starting = val
			},
			backgroundImage() {
				const background = this.asset && this.asset.background && this.asset.background.type === "image" && this.asset.background.url
				if (background) return 'background-image: url(' + this.asset.background.url + ')'
				if (this.asset && this.asset.poster) return 'background-image: url(' + this.asset.poster + ')'
				return null
			},
			customField(slug) {
				var value = null
				if (this.asset.customfieldgroups) {
					_.forEach(this.asset.customfieldgroups, group => {
						if (_.find(group.customfields, { slug: slug })){
							value = _.find(group.customfields, { slug: slug }) ? _.find(group.customfields, { slug: slug }).value : false;
						}
					})
				}
				return value
			},
		}
	}
</script>
