var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "choosePage" } },
    [
      _c(
        "b-container",
        { staticClass: "p-2 p-md-3" },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                {
                  staticClass: "text-left mw-40",
                  attrs: { cols: "12", sm: "10", md: "9", lg: "7" },
                },
                [
                  _c(
                    "div",
                    { staticClass: "block", class: _vm.blockClasses() },
                    [
                      _c("h4", [_vm._v(_vm._s(_vm.$t("questions.title")))]),
                      _c("p", { staticClass: "lead m-0 pt-3" }, [
                        _vm._v(_vm._s(_vm.$t("questions.text")) + "."),
                      ]),
                      _c(
                        "b-row",
                        { staticClass: "pt-5" },
                        [
                          _c(
                            "b-col",
                            { staticClass: "pr-0", attrs: { cols: "auto" } },
                            [
                              _c(
                                "b-btn",
                                {
                                  attrs: {
                                    variant:
                                      _vm.asset.theme
                                        .skin_block_background_color ===
                                        "dark" ||
                                      _vm.asset.theme
                                        .skin_block_background_color === "black"
                                        ? "light"
                                        : "dark",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.chooseType("presenter")
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("questions.presenter")) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { attrs: { cols: "auto" } },
                            [
                              _c(
                                "b-btn",
                                {
                                  class: _vm.asset.theme.skin_button_text_color
                                    ? "text-" +
                                      _vm.asset.theme.skin_button_text_color
                                    : null,
                                  attrs: {
                                    variant: _vm.asset.theme.skin_button_color
                                      ? _vm.asset.theme.skin_button_color
                                      : "primary",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.chooseType("moderator")
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("questions.editor")) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }