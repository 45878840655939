var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "accordion", attrs: { id: "agenda", role: "tablist" } },
    [
      _c(
        "b-card",
        { staticClass: "mb-7", attrs: { "no-body": "" } },
        [
          _c("b-card-header", { attrs: { "header-tag": "div", role: "tab" } }, [
            _c("p", { staticClass: "m-0 lead" }, [
              _vm._v(_vm._s(_vm.$t("agenda.title"))),
            ]),
          ]),
          _c(
            "b-collapse",
            { attrs: { id: "agendaList", role: "tabpanel", visible: "" } },
            [
              _c(
                "b-list-group",
                _vm._l(_vm.asset.agenda, function (agenda, index) {
                  return _c(
                    "b-list-group-item",
                    { key: index },
                    [
                      _c(
                        "b-row",
                        { attrs: { "align-v": "center" } },
                        [
                          _c(
                            "b-col",
                            {
                              staticClass: "pl-7 pr-0 py-3",
                              attrs: { cols: "auto" },
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: {
                                  icon: ["fas", "calendar"],
                                  size: "xs",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            {
                              staticClass: "pl-3 pr-7 py-3",
                              attrs: { cols: "auto" },
                            },
                            [
                              _c("p", { staticClass: "m-0 small" }, [
                                _vm._v(_vm._s(agenda.title)),
                              ]),
                              _c("p", { staticClass: "m-0 smaller" }, [
                                _vm._v(_vm._s(agenda.text)),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }