<template>
	<div id="login">
		<b-container>
			<b-row>
				<b-col cols="12" sm="10" md="9" lg="7" class="text-left mw-40">
					<div class="block" :class="blockClasses()">
						<h4>{{ $t('login.title') }}</h4>
						<p class="lead m-0 pt-3">{{ $t('login.text') }}.</p>
						<b-form class="pt-5" @submit.prevent="login">
							<b-form-group>
								<b-form-input :class="inputClasses()" type="password" name="password" :placeholder="$t('form.password')" autocomplete="password" v-model="password" />
							</b-form-group>
							<b-alert variant="danger" show v-if="errorText">
								<p class="m-0 small">{{ errorText === 'noval' ? $t('form.error-login-missing') : $t('form.error-login-wrong') }}.</p>
							</b-alert>
							<b-btn 	:variant="asset.theme.skin_button_color ? asset.theme.skin_button_color : 'primary'"
									:class="asset.theme.skin_button_text_color ? 'text-' + asset.theme.skin_button_text_color : null"
									type="submit">
								{{ $t('form.confirm') }}
							</b-btn>
						</b-form>
					</div>
				</b-col>
			</b-row>
		</b-container>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				password: null,
				errorText: null
			}
		},
		computed: {
			asset() {
				return this.$store.getters.asset
			}
		},
		methods: {
			login() {
				if(!this.password) {
					this.errorText = 'noval'
					return
				}
				this.$store.dispatch('getQuestions', { slug: this.asset.asset_category_key, moderator_password: this.password, password: this.$route.query.password, token: this.$route.query.token })
				.then(() => {
					this.$store.dispatch('setQuestionPassword', this.password)
				})
				.catch(() => {
					this.errorText = 'noauth'
					this.$store.dispatch('setQuestionPassword', null)
				})
			},
			blockClasses() {
				return [
					this.asset.theme.skin_block_background_color ? 'bg-'+this.asset.theme.skin_block_background_color : 'bg-light',
					this.asset.theme.skin_block_text_color ? 'text-'+this.asset.theme.skin_block_text_color : 'text-dark'
				]
			},
			inputClasses() {
				return [
					this.asset.theme.skin_input_background_color ? 'bg-'+this.asset.theme.skin_input_background_color : 'bg-white',
					this.asset.theme.skin_input_border_color ? 'border-'+this.asset.theme.skin_input_border_color : 'border-light',
					this.asset.theme.skin_input_text_color ? 'text-'+this.asset.theme.skin_input_text_color : 'text-dark',
					this.errorText && this.errorText === 'noval' ? 'error' : null
				]
			}
		}
	}
</script>
