var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.customField(_vm.upload_on) &&
    _vm.customField(_vm.upload_on).length !== 0
    ? _c(
        "div",
        { staticClass: "p-6 pr-8", attrs: { id: "upload" } },
        [
          _vm.customField(_vm.title)
            ? _c("h2", {
                domProps: {
                  innerHTML: _vm._s(_vm.makeBr(_vm.customField(_vm.title))),
                },
              })
            : _vm._e(),
          _vm.customField(_vm.description)
            ? _c("p", {
                staticClass: "pt-5",
                domProps: {
                  innerHTML: _vm._s(
                    _vm.makeBr(_vm.customField(_vm.description))
                  ),
                },
              })
            : _vm._e(),
          _vm.customField(_vm.upload_title) &&
          _vm.customField(_vm.upload_fields) === "1"
            ? _c("p", {
                staticClass: "big m-0 pt-5",
                domProps: {
                  innerHTML: _vm._s(
                    _vm.makeBr(_vm.customField(_vm.upload_title))
                  ),
                },
              })
            : _vm._e(),
          _vm.customField(_vm.upload_fields) === "1"
            ? _c(
                "b-form",
                {
                  staticClass: "pt-3",
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.onSubmit.apply(null, arguments)
                    },
                  },
                },
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { cols: "12" } },
                        _vm._l(_vm.customfields, function (field, ckey) {
                          return _c(
                            "b-form-group",
                            { key: "custom" + ckey },
                            [
                              _c(
                                "label",
                                {
                                  attrs: { for: "field" + _vm.$t(field.label) },
                                },
                                [_vm._v(_vm._s(_vm.$t(field.label)))]
                              ),
                              _c("b-form-input", {
                                attrs: {
                                  id: "field" + _vm.$t(field.label),
                                  type: field.type,
                                  name: _vm.$t(field.label),
                                },
                                model: {
                                  value: field.value,
                                  callback: function ($$v) {
                                    _vm.$set(field, "value", $$v)
                                  },
                                  expression: "field.value",
                                },
                              }),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                      !_vm.progress
                        ? _c(
                            "b-col",
                            { attrs: { cols: "12" } },
                            [
                              _c(
                                "b-form-group",
                                [
                                  _c("label", { attrs: { for: "upload" } }, [
                                    _vm._v(_vm._s(_vm.$t("form.upload"))),
                                  ]),
                                  _c("b-form-file", {
                                    staticClass: "dropzone",
                                    attrs: {
                                      name: "file",
                                      id: "upload",
                                      state: Boolean(_vm.file),
                                      placeholder: _vm.$t("form.upload-here"),
                                      "drop-placeholder": "Drop file here...",
                                    },
                                    model: {
                                      value: _vm.file,
                                      callback: function ($$v) {
                                        _vm.file = $$v
                                      },
                                      expression: "file",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _c("b-col", { attrs: { cols: "12" } }, [
                            _c("p", { staticClass: "m-0 py-5" }, [
                              _vm._v("Uploading..."),
                            ]),
                          ]),
                      _c(
                        "b-col",
                        { attrs: { cols: "12" } },
                        [
                          _c(
                            "b-form-group",
                            [
                              _c(
                                "label",
                                { attrs: { for: "fieldDescription" } },
                                [_vm._v(_vm._s(_vm.$t("form.description")))]
                              ),
                              _c("b-form-input", {
                                attrs: {
                                  id: "questionName",
                                  type: "text",
                                  name: "name",
                                },
                                model: {
                                  value: _vm.fields.description,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.fields, "description", $$v)
                                  },
                                  expression: "fields.description",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { cols: "12" } },
                        [
                          _c(
                            "b-form-group",
                            [
                              _c(
                                "b-form-checkbox",
                                {
                                  class: _vm.checkClasses(),
                                  attrs: {
                                    id: "questionGdpr",
                                    name: "questionGdpr",
                                    required: "",
                                  },
                                  model: {
                                    value: _vm.fields.gdpr,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.fields, "gdpr", $$v)
                                    },
                                    expression: "fields.gdpr",
                                  },
                                },
                                [
                                  _vm.customField(_vm.gdpr)
                                    ? _c("span", {
                                        staticClass: "small pl-2",
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.makeBr(
                                              _vm.customField(_vm.gdpr)
                                            )
                                          ),
                                        },
                                      })
                                    : _vm._e(),
                                  _c("font-awesome-icon", {
                                    attrs: { icon: "check", size: "sm" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      !_vm.progress && !_vm.sent
                        ? _c(
                            "b-col",
                            { staticClass: "pt-4", attrs: { cols: "12" } },
                            [
                              _c(
                                "b-btn",
                                {
                                  class: _vm.asset.theme.skin_button_text_color
                                    ? "text-" +
                                      _vm.asset.theme.skin_button_text_color
                                    : null,
                                  attrs: {
                                    type: "submit",
                                    variant: _vm.asset.theme.skin_button_color
                                      ? _vm.asset.theme.skin_button_color
                                      : "primary",
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("form.upload")))]
                              ),
                            ],
                            1
                          )
                        : !_vm.sent
                        ? _c("b-col", { attrs: { cols: "12" } }, [
                            _c("p", { staticClass: "m-0 py-5" }, [
                              _vm._v("Uploading..."),
                            ]),
                          ])
                        : _c("b-col", { attrs: { cols: "12" } }, [
                            _c(
                              "p",
                              { staticClass: "m-0 py-5 big text-primary" },
                              [_vm._v(_vm._s(_vm.$t(_vm.sent)))]
                            ),
                          ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }