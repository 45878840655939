<template>
	<div id="controllers" class="pt-4 pb-10 d-none d-md-block" :class="asset.theme.use_alternate_background_color ? 'bg-background' : null">
		<b-container fluid>
			<b-row align-h="center">
				<b-col cols="auto" class="p-1" v-if="asset.slideinstances.length">
					<b-btn 	@click="setView('video')"
							:title="viewSize === 'video' ? $t('controllers.reduce-video') : $t('controllers.enlarge-video')"
							:class="viewSize === 'video' ? 'active' : null"
							size="xl"
							variant="link-icon">
						<font-awesome-icon :icon="['fas', 'video']" />
					</b-btn>
				</b-col>
				<!-- <b-col cols="auto" class="p-0 d-none d-xl-block">
					<b-btn 	@click="setView('expand')"
							:title="viewSize === 'expand' ? $t('controllers.reduce-both') : $t('controllers.enlarge-both')"
							:class="viewSize === 'expand' ? 'active' : null"
							size="sm"
							variant="link-icon">
						<font-awesome-icon :icon="['fas', 'compress']" v-if="viewSize === 'expand'" />
						<font-awesome-icon :icon="['fas', 'expand']" v-else />
					</b-btn>
				</b-col> -->
				<b-col cols="auto" class="p-1" v-if="asset.slideinstances.length">
					<b-btn 	@click="setView('slide')"
							:title="viewSize === 'slide' ? $t('controllers.reduce-slide') : $t('controllers.enlarge-slide')"
							:class="viewSize === 'slide' ? 'active' : null"
							size="xl"
							variant="link-icon">
						<font-awesome-icon :icon="['fas', 'image']" class="btn-link-icon-xxl"/>
					</b-btn>
				</b-col>
			</b-row>
		</b-container>
	</div>
</template>
<script>
	export default {
		computed: {
			asset() {
				return this.$store.getters.asset
			},
			viewSize() {
				return this.$store.getters.viewSize
			}
		},
		methods: {
			setView(view) {
				this.$store.dispatch('viewSize', view !== this.viewSize ? view : 'both')
			}
		},
		mounted() {
			this.$store.dispatch('viewSize', this.asset.slideinstances.length ? 'both' : this.asset.type === 5 ? 'expand' : null)
		}
	}
</script>
