<template>
	<section 	id="preview"
				class="d-flex"
				v-if="asset">
		<Live />
	</section>
</template>
<script>
	import Live from '@/components/asset/Live.vue'
	export default {
		name: 'Preview',
		components: {
			Live
		},
		computed: {
			asset() {
				return this.$store.getters.asset
			}
		}
	}
</script>
