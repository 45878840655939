<template>
	<div id="poll">
        <iframe :src="asset.poll_url"></iframe>
    </div>
</template>
<script>
	export default {
		computed: {
            asset() {
				return this.$store.getters.asset
			},
			sidebar() {
				return this.$store.getters.sidebar
			}
		}
	}
</script>
