<template>
    <div class="w-100" v-if="type">
        <category v-if="type.type === 'category'" class="main" />
        <asset v-if="type.type === 'asset'" class="main" />
    </div>
</template>
<script>
    import category from '@/views/Category.vue'
    import asset from '@/views/Asset.vue'
    export default {
        components: {
            category,
            asset
        },
        computed: {
            type() {
                return this.$store.getters.type
            }
        }
    }
</script>
