var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "breakouts" } },
    [
      _c(
        "b-container",
        [
          _c(
            "b-row",
            { staticClass: "py-5" },
            _vm._l(_vm.breakouts, function (breakout, i) {
              return _c(
                "b-col",
                { key: i, staticClass: "py-5", attrs: { cols: "12" } },
                [
                  _c(
                    "div",
                    { staticClass: "breakout" },
                    [
                      _c(
                        "b-row",
                        { attrs: { "align-v": "center" } },
                        [
                          _c(
                            "b-col",
                            {
                              staticClass: "pr-lg-0 pt-5 pt-lg-0",
                              attrs: {
                                cols: "12",
                                lg: "4",
                                order: "2",
                                "order-lg": "1",
                              },
                            },
                            [
                              _c(
                                "b-aspect",
                                {
                                  staticClass:
                                    "bg-cover bg-scroll position-relative",
                                  class: !breakout.thumbnail_url
                                    ? "bg-secondary"
                                    : null,
                                  style:
                                    "background-image: url(" +
                                    breakout.thumbnail_url +
                                    ");",
                                  attrs: { aspect: "16:9" },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "bg-dark text-light p-2 position-absolute",
                                    },
                                    [
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "m-0 text-uppercase smaller",
                                        },
                                        [_vm._v("Breakout session")]
                                      ),
                                    ]
                                  ),
                                  !breakout.thumbnail_url
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "h-100 w-100 position-absolute d-flex justify-content-center align-items-center",
                                        },
                                        [
                                          _c("font-awesome-icon", {
                                            attrs: {
                                              icon: ["fas", "image"],
                                              size: "4x",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            {
                              attrs: {
                                cols: "12",
                                lg: "8",
                                order: "1",
                                "order-lg": "2",
                              },
                            },
                            [
                              _c("div", { staticClass: "pl-md-5" }, [
                                _c("h4", { staticClass: "pb-3" }, [
                                  _vm._v(_vm._s(breakout.title)),
                                ]),
                                _c("p", { staticClass: "pb-3" }, [
                                  _vm._v(_vm._s(breakout.description)),
                                ]),
                                _c(
                                  "a",
                                  {
                                    staticClass: "btn",
                                    class: _vm.channel.skin_button_color
                                      ? "btn-" + _vm.channel.skin_button_color
                                      : "btn-primary",
                                    attrs: {
                                      href: breakout.url,
                                      target: "_blank",
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("common.visit")))]
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }