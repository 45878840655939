<template>
	<section 	id="questions"
				class="d-flex pt-10"
				:class="backgroundVideo && !questionType || backgroundImage && !questionType ? 'justify-content-center bg-cover' : !questionType ? 'justify-content-center bg-gray' : 'bg-gray'"
				:style="backgroundImage && !questionType ? backgroundImage : null"
				v-if="asset">
		<video class="bg-video" autoplay loop muted playsinline webkit-playsinline v-if="backgroundVideo && !questionType">
			<source :src="backgroundVideo" :type="genFileType">
		</video>
		<Moderator v-if="getPassword() && questionType === 'moderator'" />
		<Presenter v-else-if="getPassword() && questionType" />
		<Choose v-else-if="getPassword()" />
		<Login :error="error" v-else />
	</section>
</template>
<script>
	import Moderator from '@/components/questions/Moderator.vue'
	import Presenter from '@/components/questions/Presenter.vue'
	import Choose from '@/components/questions/Choose.vue'
	import Login from '@/components/questions/Login.vue'
	export default {
		data() {
			return {
				error: null
			}
		},
		components: {
			Moderator,
			Presenter,
			Choose,
			Login
		},
		computed: {
			asset() {
				return this.$store.getters.asset
			},
			pusher() {
				return this.$store.getters.pusher
			},
			questions() {
				return this.$store.getters.questions
			},
			questionPassword() {
				return this.$store.getters.questionPassword
			},
			questionType() {
				return this.$store.getters.questionType
			},
			backgroundImage() {
				const background = this.asset.background && this.asset.background.type === "image" && this.asset.background.url
				if(background) return 'background-image: url('+this.asset.background.url+')'
				if(this.asset.poster) return 'background-image: url('+this.asset.poster+')'
				return null
			},
			backgroundVideo() {
				const background = this.asset.background && this.asset.background.type === "video" && this.asset.background.url
				if(background) return this.asset.background.url
				return null
			},
			genFileType() {
				const ext = this.asset.background ? this.asset.background.url.split('.').pop() : null
				if(ext) return this.asset.background.type + '/' + ext
				return null
			}
		},
		methods: {
			chooseType(type) {
				this.$store.dispatch('setQuestionType', type)
			},
			getPassword() {
				if (this.questionPassword) return this.questionPassword
				if (sessionStorage.getItem('questionPassword')) return sessionStorage.getItem('questionPassword')
				if (this.$route.query.moderator) {
					this.$store.dispatch('setQuestionPassword', this.$route.query.moderator)
					return this.$route.query.moderator
				}
				return null
			},
			connectToPusher(password) {
				var pusher = new window.Pusher(process.env.VUE_APP_PUSHER, {
						cluster: 'eu'
					})
					pusher.connection.bind('error', (err) => {
						throw err
				})
				const url = `${this.asset.asset_key}-questions-${password}`
				var questionsChannel = pusher.subscribe(url)
				const data = {
					slug: this.asset.asset_category_key, 
					password: this.$route.query.password,
					moderator_password: password,
					token: this.$route.query.token,
				}
				questionsChannel.bind('my-event', (event) => {
					this.$toasted.success(this.$t('questions.event-'+event.event), { position: 'bottom-right' })
					this.$store.dispatch('getQuestions', data) 
				})
				this.$store.dispatch('pusher', pusher)
			},
			getQuestions() {
				const password = this.getPassword()
				const data = {
					slug: this.asset.asset_category_key, 
					token: this.$route.query.token,
					password: this.$route.query.password,
					moderator_password: password,
				}
				this.$store.dispatch('getQuestions', data)
				.then(() => {
					this.connectToPusher(password)
				})
				.catch(() => {
					this.error = 'noauth'
					this.$store.dispatch('setQuestionPassword', null)
				})
			}
		},
		watch: {
			questionType() {
				this.getQuestions()
			}
		}
	}
</script>
