var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.type
    ? _c(
        "div",
        { staticClass: "w-100" },
        [
          _vm.type.type === "category"
            ? _c("category", { staticClass: "main" })
            : _vm._e(),
          _vm.type.type === "asset"
            ? _c("asset", { staticClass: "main" })
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }