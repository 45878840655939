import { render, staticRenderFns } from "./Presenter.vue?vue&type=template&id=585cd766&"
import script from "./Presenter.vue?vue&type=script&lang=js&"
export * from "./Presenter.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/jesperpettersson/Repos/creo-live/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('585cd766')) {
      api.createRecord('585cd766', component.options)
    } else {
      api.reload('585cd766', component.options)
    }
    module.hot.accept("./Presenter.vue?vue&type=template&id=585cd766&", function () {
      api.rerender('585cd766', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/questions/Presenter.vue"
export default component.exports