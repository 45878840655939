var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "h-100-header", attrs: { id: "postlive" } },
    [
      _vm.category && _vm.assets
        ? _c(
            "b-container",
            { staticClass: "h-100  px-0 pl-3", attrs: { fluid: "" } },
            [
              _c(
                "b-row",
                { staticClass: "h-100" },
                [
                  _c(
                    "b-col",
                    { staticClass: "h-50-pre", attrs: { cols: "12", xl: "4" } },
                    [
                      _c(
                        "b-row",
                        {
                          staticClass:
                            "pl-1 pl-lg-7 h-100 pb-3 pt-4 pt-xl-0 d-flex justify-content-start",
                        },
                        [
                          _c("b-col", { attrs: { cols: "12" } }, [
                            _vm.category.title
                              ? _c("h1", {
                                  staticClass: "pt-01",
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.makeBr(_vm.category.title)
                                    ),
                                  },
                                })
                              : _vm._e(),
                          ]),
                          _c(
                            "b-col",
                            {
                              staticClass:
                                "text-dark pl-5 pr-8 d-flex flex-wrap align-content-start align-content-md-end align-content-xl-start min-h-16",
                              attrs: { cols: "12", md: "8", xl: "12" },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "pb-5 pt-5 w-100" },
                                [
                                  _c("p", {
                                    staticClass: "big m-0 pt-3 pb-5",
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.customField(
                                          _vm.lang + "description"
                                        )
                                      ),
                                    },
                                  }),
                                  _c("p", {
                                    staticClass: "big m-0 pt-3 pb-5",
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.customField(_vm.lang + "message")
                                      ),
                                    },
                                  }),
                                  _c("hr"),
                                  _c(
                                    "b-row",
                                    {},
                                    _vm._l(_vm.assets, function (asset, akey) {
                                      return _c(
                                        "b-col",
                                        {
                                          key: "a" + akey,
                                          staticClass: "px-2 pt-5",
                                          attrs: { cols: "4" },
                                        },
                                        [
                                          _c(
                                            "router-link",
                                            {
                                              staticClass:
                                                "w-100 btn btn-filter button px-1",
                                              attrs: {
                                                to: {
                                                  name: "type",
                                                  params: {
                                                    slug: asset.asset_category_key,
                                                  },
                                                },
                                                variant: _vm.channel
                                                  .skin_button_color
                                                  ? _vm.channel
                                                      .skin_button_color
                                                  : "primary",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "language." +
                                                        asset.language.name
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("b-col", {
                    staticClass: "bg-skanska-cover",
                    style: _vm.backgroundImage() ? _vm.backgroundImage() : null,
                    attrs: { cols: "6", xl: "4" },
                  }),
                  _c("b-col", {
                    staticClass: "bg-skanska-s",
                    attrs: { cols: "6", xl: "4" },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }