var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "h-100-header", attrs: { id: "prelive" } },
    [
      _c(
        "b-container",
        { staticClass: "h-100  px-0 pl-3", attrs: { fluid: "" } },
        [
          _c(
            "b-row",
            { staticClass: "h-100" },
            [
              _c(
                "b-col",
                { staticClass: "h-50-pre", attrs: { cols: "12", xl: "4" } },
                [
                  _c(
                    "b-row",
                    {
                      staticClass:
                        "pl-1 pl-lg-7 h-100 pb-3 pt-4 pt-xl-0 d-flex justify-content-start",
                    },
                    [
                      _c(
                        "b-col",
                        { staticClass: "py-3 ", attrs: { cols: "12" } },
                        [
                          _vm.customField("label")
                            ? _c("h2", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.makeBr(_vm.customField("label"))
                                  ),
                                },
                              })
                            : _vm._e(),
                          _c("h1", {
                            staticClass: "pt-01",
                            domProps: {
                              innerHTML: _vm._s(_vm.makeBr(_vm.asset.title)),
                            },
                          }),
                        ]
                      ),
                      _c(
                        "b-col",
                        {
                          staticClass:
                            "text-dark pl-5 pr-8 d-flex flex-wrap align-content-start align-content-md-end align-content-xl-start",
                          attrs: {
                            cols: "12",
                            offset: "0",
                            md: "8",
                            xl: "12",
                            "offset-md": "4",
                            "offset-xl": "0",
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "pb-5 pt-5 w-100" },
                            [
                              _vm.asset.is_countdown && _vm.starting
                                ? _c("p", { staticClass: "big m-0" }, [
                                    _vm._v(
                                      _vm._s(_vm.$t("countdown.event")) + "..."
                                    ),
                                  ])
                                : _vm.asset.prelive
                                ? _c("p", {
                                    staticClass: "big m-0",
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.makeBr(_vm.asset.prelive)
                                      ),
                                    },
                                  })
                                : _vm.asset.description
                                ? _c("p", {
                                    staticClass: "big m-0",
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.makeBr(_vm.asset.description)
                                      ),
                                    },
                                  })
                                : _vm._e(),
                              _vm.asset.is_countdown
                                ? _c("countdown", {
                                    staticClass:
                                      "mt-3 mt-xl-6 py-4 border-countdown px-xl-0",
                                    on: { cdStarting: _vm.cdStarts },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("b-col", {
                staticClass: "bg-skanska-cover",
                style: _vm.backgroundImage() ? _vm.backgroundImage() : null,
                attrs: { cols: "6", xl: "4" },
              }),
              _c(
                "b-col",
                {
                  class:
                    _vm.customField(_vm.upload_on) &&
                    _vm.customField(_vm.upload_on).length !== 0
                      ? ""
                      : "bg-skanska-k",
                  attrs: { cols: "6", xl: "4" },
                },
                [
                  _vm.customField(_vm.upload_on) &&
                  _vm.customField(_vm.upload_on).length !== 0
                    ? _c("upload")
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }