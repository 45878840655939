var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.asset
    ? _c(
        "section",
        {
          staticClass: "d-flex pt-10",
          class:
            (_vm.backgroundVideo && !_vm.questionType) ||
            (_vm.backgroundImage && !_vm.questionType)
              ? "justify-content-center bg-cover"
              : !_vm.questionType
              ? "justify-content-center bg-gray"
              : "bg-gray",
          style:
            _vm.backgroundImage && !_vm.questionType
              ? _vm.backgroundImage
              : null,
          attrs: { id: "questions" },
        },
        [
          _vm.backgroundVideo && !_vm.questionType
            ? _c(
                "video",
                {
                  staticClass: "bg-video",
                  attrs: {
                    autoplay: "",
                    loop: "",
                    muted: "",
                    playsinline: "",
                    "webkit-playsinline": "",
                  },
                  domProps: { muted: true },
                },
                [
                  _c("source", {
                    attrs: { src: _vm.backgroundVideo, type: _vm.genFileType },
                  }),
                ]
              )
            : _vm._e(),
          _vm.getPassword() && _vm.questionType === "moderator"
            ? _c("Moderator")
            : _vm.getPassword() && _vm.questionType
            ? _c("Presenter")
            : _vm.getPassword()
            ? _c("Choose")
            : _c("Login", { attrs: { error: _vm.error } }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }