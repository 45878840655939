<template>
	<div id="chat">
		<iframe :src="chatUrl"></iframe>
    </div>
</template>
<script>
	export default {
		computed: {
			asset() {
				return this.$store.getters.asset
			},
			sidebar() {
				return this.$store.getters.sidebar
			},
			attendee() {
				return this.$store.getters.attendee
			},
			chatUrl() {
				if(this.userName()) {
					return `${this.asset.chat_url}?username=${this.userName()}`
				}
				return this.asset.chat_url
			}
		},
		methods: {
			userName() {
				if(this.$route.query.token) {
					var base64Url = this.$route.query.token.split('.')[1]
					var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
					var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
						return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
					}).join(''))
					return JSON.parse(jsonPayload).name
				}
				return null
			}
		}
	}
</script>
