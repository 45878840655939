var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.counterObj
    ? _c(
        "div",
        { attrs: { id: "countdown" } },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                {
                  staticClass: "text-center flex-lg-grow-1 px-0 px-lg-0",
                  attrs: { cols: "3" },
                },
                [
                  _c(
                    "h2",
                    { staticClass: "m-0 bigger first font-weight-bold" },
                    [_vm._v(_vm._s(_vm.counterObj.days))]
                  ),
                  _c("p", { staticClass: "m-0 smaller" }, [
                    _vm._v(_vm._s(_vm.$t("countdown.days"))),
                  ]),
                ]
              ),
              _c(
                "b-col",
                {
                  staticClass: "text-center flex-lg-grow-1 px-0 px-lg-0",
                  attrs: { cols: "3" },
                },
                [
                  _c("h2", { staticClass: "m-0 bigger font-weight-bold" }, [
                    _vm._v(_vm._s(_vm.counterObj.hours)),
                  ]),
                  _c("p", { staticClass: "m-0 smaller" }, [
                    _vm._v(_vm._s(_vm.$t("countdown.hours"))),
                  ]),
                ]
              ),
              _c(
                "b-col",
                {
                  staticClass: "text-center flex-lg-grow-1 px-0 px-lg-0",
                  attrs: { cols: "3" },
                },
                [
                  _c("h2", { staticClass: "m-0 bigger" }, [
                    _vm._v(_vm._s(_vm.counterObj.minutes)),
                  ]),
                  _c("p", { staticClass: "m-0 smaller" }, [
                    _vm._v(_vm._s(_vm.$t("countdown.minutes"))),
                  ]),
                ]
              ),
              _c(
                "b-col",
                {
                  staticClass: "text-center flex-lg-grow-1 px-0 px-lg-0",
                  attrs: { cols: "3" },
                },
                [
                  _c("h2", { staticClass: "m-0 bigger font-weight-bold" }, [
                    _vm._v(_vm._s(_vm.counterObj.seconds)),
                  ]),
                  _c("p", { staticClass: "m-0 smaller" }, [
                    _vm._v(_vm._s(_vm.$t("countdown.seconds"))),
                  ]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }