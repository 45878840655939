<template>
	<div id="agenda" class="accordion" role="tablist">
		<b-card class="mb-7" no-body>
			<b-card-header header-tag="div" role="tab">
				<p class="m-0 lead">{{ $t('agenda.title') }}</p>
			</b-card-header>
			<b-collapse id="agendaList" role="tabpanel" visible>
				<b-list-group>
					<b-list-group-item 	v-for="(agenda, index) in asset.agenda"
										:key="index">
						<b-row align-v="center">
							<b-col cols="auto" class="pl-7 pr-0 py-3">
								<font-awesome-icon :icon="['fas', 'calendar']" size="xs"/>
							</b-col>
							<b-col cols="auto" class="pl-3 pr-7 py-3">
								<p class="m-0 small">{{ agenda.title }}</p>
								<p class="m-0 smaller">{{ agenda.text }}</p>
							</b-col>
						</b-row>
					</b-list-group-item>
				</b-list-group>
			</b-collapse>
		</b-card>
	</div>
</template>
<script>
	export default {
		computed: {
			asset() {
				return this.$store.getters.asset
			}
		}
	}
</script>
