<template>
	<div id="meeting">
		<b-embed type="iframe" :aspect="'16by9'" :src="asset.webrtc_url" allow="camera; microphone; fullscreen; speaker; display-capture;" allowfullscreen />
	</div>
</template>
<script>
	export default {
		computed: {
			asset() {
				return this.$store.getters.asset
			}
		}
	}
</script>
