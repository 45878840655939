var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "pt-4 pb-10 d-none d-md-block",
      class: _vm.asset.theme.use_alternate_background_color
        ? "bg-background"
        : null,
      attrs: { id: "controllers" },
    },
    [
      _c(
        "b-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "b-row",
            { attrs: { "align-h": "center" } },
            [
              _vm.asset.slideinstances.length
                ? _c(
                    "b-col",
                    { staticClass: "p-1", attrs: { cols: "auto" } },
                    [
                      _c(
                        "b-btn",
                        {
                          class: _vm.viewSize === "video" ? "active" : null,
                          attrs: {
                            title:
                              _vm.viewSize === "video"
                                ? _vm.$t("controllers.reduce-video")
                                : _vm.$t("controllers.enlarge-video"),
                            size: "xl",
                            variant: "link-icon",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.setView("video")
                            },
                          },
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: ["fas", "video"] },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.asset.slideinstances.length
                ? _c(
                    "b-col",
                    { staticClass: "p-1", attrs: { cols: "auto" } },
                    [
                      _c(
                        "b-btn",
                        {
                          class: _vm.viewSize === "slide" ? "active" : null,
                          attrs: {
                            title:
                              _vm.viewSize === "slide"
                                ? _vm.$t("controllers.reduce-slide")
                                : _vm.$t("controllers.enlarge-slide"),
                            size: "xl",
                            variant: "link-icon",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.setView("slide")
                            },
                          },
                        },
                        [
                          _c("font-awesome-icon", {
                            staticClass: "btn-link-icon-xxl",
                            attrs: { icon: ["fas", "image"] },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }