var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.asset
    ? _c(
        "section",
        {
          staticClass: "d-flex",
          class: _vm.getClasses(),
          attrs: { id: "asset" },
        },
        [
          [0].includes(_vm.asset.broadcasting) || _vm.dateCheck
            ? _c("PreLive")
            : [1].includes(_vm.asset.broadcasting)
            ? _c("Live")
            : [2, 3].includes(_vm.asset.broadcasting) &&
              (_vm.asset.type === 5 || _vm.asset.status_id !== 5)
            ? _c("PostLive")
            : _c("OnDemand"),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }