<template>
	<section 	id="asset"
				class="d-flex"
				v-if="asset"
				:class="getClasses()">
		<PreLive v-if="[0].includes(asset.broadcasting) || dateCheck" />
		<Live v-else-if="[1].includes(asset.broadcasting)" />
		<PostLive v-else-if="[2,3].includes(asset.broadcasting) && (asset.type === 5 || asset.status_id !== 5)" />
		<OnDemand v-else />
	</section>
</template>
<script>
	import PreLive from '@/components/asset/PreLive.vue'
	import Live from '@/components/asset/Live.vue'
	import PostLive from '@/components/asset/PostLive.vue'
	import OnDemand from '@/components/asset/OnDemand.vue'
	export default {
		name: 'Asset',
		data() {
			return {
				dateCheck: false
			}
		},
		components: {
			PreLive,
			Live,
			PostLive,
			OnDemand
		},
		computed: {
			asset() {
				return this.$store.getters.asset
			}
		},
		methods: {
			isPrePostLive() {
				if(this.$route.name === 'preview') return false
				return ([0, 2, 3].includes(this.asset.broadcasting) && (this.asset.type === 5 || this.asset.status_id !== 5)) ? true : false
			},
			getClasses() {
				return [
					this.isPrePostLive() ? 'justify-content-center' : null
				]
			},
			countdownOndemand() {
				this.dateCheck = this.$moment(this.$moment.utc()).isBefore(this.asset.broadcasting_estimated_start)
				setInterval(() => {
					this.dateCheck = this.$moment(this.$moment.utc()).isBefore(this.asset.broadcasting_estimated_start)
				}, 1000)
			}
		},
		mounted() {
			if(this.asset && this.asset.type === 1 && this.asset.is_countdown) this.countdownOndemand(this.asset)
		}
	}
</script>
