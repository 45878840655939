<template>
    <div id="choosePage">
        <b-container class="p-2 p-md-3">
            <b-row>
                <b-col cols="12" sm="10" md="9" lg="7" class="text-left mw-40">
                    <div class="block" :class="blockClasses()">
                        <h4>{{ $t('questions.title') }}</h4>
                        <p class="lead m-0 pt-3">{{ $t('questions.text') }}.</p>
                        <b-row class="pt-5">
                            <b-col cols="auto" class="pr-0">
                                <b-btn 	:variant="asset.theme.skin_block_background_color === 'dark' || asset.theme.skin_block_background_color === 'black' ? 'light' : 'dark'"
                                        @click="chooseType('presenter')">
                                    {{ $t('questions.presenter') }}
                                </b-btn>
                            </b-col>
                            <b-col cols="auto">
                                <b-btn 	:variant="asset.theme.skin_button_color ? asset.theme.skin_button_color : 'primary'"
                                        :class="asset.theme.skin_button_text_color ? 'text-' + asset.theme.skin_button_text_color : null"
                                        @click="chooseType('moderator')">
                                    {{ $t('questions.editor') }}
                                </b-btn>
                            </b-col>
                        </b-row>
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>
<script>
	export default {
		computed: {
			asset() {
				return this.$store.getters.asset
			}
        },
        methods: {
			chooseType(type) {
				this.$store.dispatch('setQuestionType', type)
			},
            blockClasses() {
				return [
					this.asset.theme.skin_block_background_color ? 'bg-'+this.asset.theme.skin_block_background_color : 'bg-light',
					this.asset.theme.skin_block_text_color ? 'text-'+this.asset.theme.skin_block_text_color : 'text-dark'
				]
			}
        }
    }
</script>
