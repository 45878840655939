<template>
    <div id="sidebar" :class="sidebarClasses()">
        <a href="#" class="toggle-close"
            :class="channel.skin_block_background_color ? 'bg-' + channel.skin_block_background_color : 'bg-light'"
            @click.prevent="toggleSidebar(false)">
            <span :class="channel.skin_block_text_color ? 'bg-' + channel.skin_block_text_color : 'bg-dark'"></span>
            <span :class="channel.skin_block_text_color ? 'bg-' + channel.skin_block_text_color : 'bg-dark'"></span>
            <span :class="channel.skin_block_text_color ? 'bg-' + channel.skin_block_text_color : 'bg-dark'"></span>
        </a>
        <div id="tabs" class="d-md-none">
            <b-row :class="channel.skin_block_text_color ? 'text-' + channel.skin_block_text_color : 'text-dark'">
                <b-col class="text-center px-0" v-if="asset.asset_qa && asset.asset_qa.enabled && asset.asset_qa.sidebar">
                    <a href="#" @click.prevent="toggleSidebar('qa')" class="small tab border-bottom"
                        :class="sidebar === 'qa' ? 'active' : null" :title="$t('common.ask-question')">
                        {{ $t('common.ask') }}
                    </a>
                </b-col>
                <b-col class="text-center px-0" v-if="asset.chat_url">
                    <a href="#" @click.prevent="toggleSidebar('chat')" class="small tab border-bottom"
                        :class="sidebar === 'chat' ? 'active' : null" :title="$t('common.chat')">
                        {{ $t('common.chat') }}
                    </a>
                </b-col>
                <b-col class="text-center px-0" v-if="asset.poll_url">
                    <a href="#" @click.prevent="toggleSidebar('poll')" class="small tab border-bottom"
                        :class="sidebar === 'poll' ? 'active' : null" :title="$t('common.poll')">
                        {{ $t('common.poll') }}
                    </a>
                </b-col>
            </b-row>
        </div>
        <Ask v-if="asset.asset_qa && asset.asset_qa.enabled && asset.asset_qa.sidebar && sidebar === 'qa'"></Ask>
        <Chat v-if="asset.chat_url && sidebar === 'chat'"></Chat>
        <Poll v-if="asset.poll_url && sidebar === 'poll'"></Poll>
    </div>
</template>
<script>
import Ask from '@/components/asset/sidebar/Ask.vue'
import Chat from '@/components/asset/sidebar/Chat.vue'
import Poll from '@/components/asset/sidebar/Poll.vue'
export default {
    data() {
        return {
            sidebarType: null,
            tempAsset: null
        }
    },
    computed: {
        asset() {
            return this.$store.getters.asset
        },
        channel() {
            return this.$store.getters.channel
        },
        sidebar() {
            return this.$store.getters.sidebar
        }
    },
    components: {
        Ask,
        Chat,
        Poll
    },
    methods: {
        toggleSidebar(val) {
            this.$store.dispatch('sidebar', val)
        },
        sidebarClasses() {
            return [
                this.sidebar ? 'visible' : null,
                this.channel.skin_block_background_color ? 'bg-' + this.channel.skin_block_background_color : 'bg-light',
                this.channel.skin_block_text_color ? 'text-' + this.channel.skin_block_text_color : 'text-dark'
            ]
        },
        initSidebar() {
            if (this.asset.is_sidebar_open && this.asset.open_sidebar_type !== 'none') this.sidebarType = this.asset.open_sidebar_type
            else if (this.asset.is_sidebar_open && this.asset.asset_qa && this.asset.asset_qa.enabled && this.asset.asset_qa.sidebar) this.sidebarType = 'qa'
            else if (this.asset.is_sidebar_open && this.asset.chat_url) this.sidebarType = 'chat'
            else if (this.asset.is_sidebar_open && this.asset.poll_url) this.sidebarType = 'poll'
            else this.sidebarType = null
        },
    },
    mounted() {
        this.initSidebar()
        this.tempAsset = _.cloneDeep(this.asset)
        this.$store.dispatch('sidebar', this.sidebarType)
        this.toggleSidebar(this.sidebarType)
    },
    watch: {
        asset(data) {
            if (data.open_sidebar_type !== this.tempAsset.open_sidebar_type && data.open_sidebar_type !== this.sidebarType) {   
                this.sidebarType = (data.open_sidebar_type === 'none' ? null : data.open_sidebar_type)
                this.tempAsset = _.cloneDeep(data)
                this.$store.dispatch('sidebar', this.sidebarType)
                this.toggleSidebar(this.sidebarType) 
            }
        }
    }

}
</script>