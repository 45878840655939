var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "login" } },
    [
      _c(
        "b-container",
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                {
                  staticClass: "text-left mw-40",
                  attrs: { cols: "12", sm: "10", md: "9", lg: "7" },
                },
                [
                  _c(
                    "div",
                    { staticClass: "block", class: _vm.blockClasses() },
                    [
                      _c("h4", [_vm._v(_vm._s(_vm.$t("login.title")))]),
                      _c("p", { staticClass: "lead m-0 pt-3" }, [
                        _vm._v(_vm._s(_vm.$t("login.text")) + "."),
                      ]),
                      _c(
                        "b-form",
                        {
                          staticClass: "pt-5",
                          on: {
                            submit: function ($event) {
                              $event.preventDefault()
                              return _vm.login.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _c(
                            "b-form-group",
                            [
                              _c("b-form-input", {
                                class: _vm.inputClasses(),
                                attrs: {
                                  type: "password",
                                  name: "password",
                                  placeholder: _vm.$t("form.password"),
                                  autocomplete: "password",
                                },
                                model: {
                                  value: _vm.password,
                                  callback: function ($$v) {
                                    _vm.password = $$v
                                  },
                                  expression: "password",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm.errorText
                            ? _c(
                                "b-alert",
                                { attrs: { variant: "danger", show: "" } },
                                [
                                  _c("p", { staticClass: "m-0 small" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.errorText === "noval"
                                          ? _vm.$t("form.error-login-missing")
                                          : _vm.$t("form.error-login-wrong")
                                      ) + "."
                                    ),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                          _c(
                            "b-btn",
                            {
                              class: _vm.asset.theme.skin_button_text_color
                                ? "text-" +
                                  _vm.asset.theme.skin_button_text_color
                                : null,
                              attrs: {
                                variant: _vm.asset.theme.skin_button_color
                                  ? _vm.asset.theme.skin_button_color
                                  : "primary",
                                type: "submit",
                              },
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("form.confirm")) + " ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }