<template>
	<div id="slides" class="pt-3">
		<b-container fluid class="position-relative">
			<div ref="slidesContainer" id="slidesContainer" :style="{ height: slideHeight+'px' }">
				<div class="slides" :style="{ width: totalContainerWidth+'px' }">
					<div 	class="slide bg-cover bg-scroll"
							:class="[ currentSlide && currentSlide.id === slide.id ? 'active' : '', live ? 'cursor-zoom-in' : 'cursor-pointer']"
							:style="{ backgroundImage: 'url(' + slideUrl(slide) + ')', width: slideWidth+'px', height: slideHeight+'px', marginRight: key+1 !== slides.length ? slideGutter+'px' : '' }"
							v-for="(slide, key) in slides"
							:data-key="key"
							:data-id="slide.id"
							:data-slideid="slide.slide_id"
							:key="key"
							@click="live ? zoomSlide(slide) : seekToTime(slide.timecode)">
					</div>
				</div>
			</div>
			<b-btn 	variant="dark"
					class="scroll scroll-left"
					@click="scrollLeft()"
					v-if="slideLeft">
				<font-awesome-icon :icon="['fas', 'chevron-left']" />
			</b-btn>
			<b-btn 	variant="dark"
					class="scroll scroll-right"
					@click="scrollRight()"
					v-if="slideRight">
				<font-awesome-icon :icon="['fas', 'chevron-right']" />
			</b-btn>
		</b-container>
		<b-container fluid >
			<div class="border-bottom pt-8" />
		</b-container>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				slides: null,
				containerWidth: 0,
				totalContainerWidth: 0,
				slideWidth: 0,
				slideHeight: 0,
				ratio: 0,
				resizing: false,
				newVisibleSlides: 0,
				slideLeft: false,
				slideRight: false,
				activeSlideIndex: null
			}
		},
		props: {
			live: {
				type: Boolean,
				default: false
			},
			visibleSlides: {
				type: Number,
				default: 8
			},
			slideGutter: {
				type: Number,
				default: 10
			}
		},
		computed: {
			asset() {
				return this.$store.getters.asset
			},
			currentTime() {
				return this.$store.getters.currentTime
			},
			currentSlide() {
				return this.$store.getters.currentSlide
			},
			stream() {
				return this.$store.getters.stream
			}
		},
		methods: {
			slideUrl(slide) {
				return this.asset.slide_path + '320/' +  slide.path;
			},
			calcSlides() {
				this.getImageRatio(this.slideUrl(this.slides[0]), function(img) {
					this.ratio = img.target.naturalHeight / img.target.naturalWidth
					this.containerWidth = this.$refs.slidesContainer.clientWidth
					this.newVisibleSlides = document.body.clientWidth < 992 ? this.visibleSlides/2 : this.visibleSlides
					const totalGutter = (this.newVisibleSlides * this.slideGutter) - this.slideGutter
					const totalWidth = this.containerWidth - totalGutter
					this.slideWidth = totalWidth / this.newVisibleSlides
					this.slideHeight = this.slideWidth * this.ratio
					this.totalContainerWidth = (this.slides.length * this.slideWidth) + (this.slides.length * this.slideGutter) - this.slideGutter
				}.bind(this))
				const lastIndex = this.slides.length - 1
				this.getImageRatio(this.slideUrl(this.slides[lastIndex]), function(img) {
					const ratio = img.target.naturalHeight / img.target.naturalWidth
					const aspect = ratio > 0.95 ? '1by1' : ratio < 0.5 ? '21by9' : ratio > 0.6 ? '4by3' : '16by9'
					this.$store.dispatch('setBigSlideRatio', { ratio: ratio, aspect: aspect })
				}.bind(this))
			},
			zoomSlide(slide) {
				this.$store.dispatch('viewSlide', slide)
				this.$root.$emit('bv::show::modal', 'slideModal')
			},
			scrollToSlide(slide) {
				const el = document.querySelector('.slide[data-id="'+slide.id+'"]')
				let offset = this.containerWidth/2
					offset = offset-this.slideWidth/2
				this.$scrollTo(el, {
					container: '#slidesContainer',
					cancelable: false,
					x: true,
					y: false,
					offset: -offset,
					onDone: this.checkScroll()
				})
			},
			seekToTime(time) {
				let data = { currentTime: time }
				let frame = document.querySelector('iframe')
				frame.contentWindow.postMessage(data, '*')
			},
			checkScroll() {
				setTimeout(() => { 
					const scrollableWidth = this.totalContainerWidth - this.containerWidth
					if(scrollableWidth > 0 && this.slides[this.activeSlideIndex-1]) { this.slideLeft = true } else { this.slideLeft = false }
					if(scrollableWidth > 0 && this.slides[this.activeSlideIndex+1]) { this.slideRight = true } else { this.slideRight = false }
				}, 500)
			},
			scrollLeft() {
				this.slides.forEach((slide, index) =>  {
					if(slide.id === this.currentSlide.id) {
						this.activeSlideIndex = index-1
					}
				})
				if(this.live) {
					this.$store.dispatch('setCurrentSlide', this.slides[this.activeSlideIndex] ? this.slides[this.activeSlideIndex] : this.slides[0])
				} else {
					this.seekToTime(this.slides[this.activeSlideIndex].timecode)
				}
			},
			scrollRight() {
				this.slides.forEach((slide, index) =>  {
					if(slide.id === this.currentSlide.id) {
						this.activeSlideIndex = index+1
					}
				})
				if(this.live) {
					this.$store.dispatch('setCurrentSlide', this.slides[this.activeSlideIndex] ? this.slides[this.activeSlideIndex] : this.slides[this.slides.length-1])
				} else {
					this.seekToTime(this.slides[this.activeSlideIndex].timecode)
				}
			},
			getImageRatio(src, callback) {
				let img
				img = new Image()
				img.onload = callback
				img.src = src
			},
			windowResize() {
				clearTimeout(this.resizing)
				this.resizing = setTimeout(() => {
					this.calcSlides()
					if(!this.currentSlide) return
					setTimeout(() => {
						this.scrollToSlide(this.currentSlide)
					}, 500)
				}, 1000)
			}
		},
		mounted() {
			this.slides = this.asset.slideinstances
			this.calcSlides()
			this.activeSlideIndex = this.live ? this.slides.length-1 : 0
			this.$store.dispatch('setCurrentSlide', this.slides[this.activeSlideIndex])
			this.$store.dispatch('viewSlide', this.slides[this.activeSlideIndex])
			window.addEventListener('resize', this.windowResize)
		},
		watch: {
			asset(data) {	
				if (this.live && this.asset.type !== 5) {
					const stream = data.streams.find(stream => stream.id == this.stream)
					const latency = stream ? stream.stream.latency * 1000 : 0
					setTimeout(() => {
						this.slides = data.slideinstances
						this.calcSlides()
						this.activeSlideIndex = this.slides.length - 1
						let activeSlide = this.slides[this.activeSlideIndex]
						this.$store.dispatch('setCurrentSlide', activeSlide)
					}, latency)
				} else if (this.asset.type === 5){ 
					this.slides = data.slideinstances
					this.calcSlides()
					this.activeSlideIndex = this.slides.length - 1
					let activeSlide = this.slides[this.activeSlideIndex]
					this.$store.dispatch('setCurrentSlide', activeSlide)
				}else {
					let activeSlide = this.slides[0]
					this.$store.dispatch('setCurrentSlide', activeSlide)
				}
			},
			currentTime(data) {
				if (this.live) return

				const slides = this.slides
				let activeSlide
				slides.forEach((slide, index) =>  {
					if(slide.timecode <= data) {
						slide.id = index
						activeSlide = slide
					}
				})
				this.$store.dispatch('setCurrentSlide', activeSlide ? activeSlide : null)
			},
			currentSlide(data) {
				if (data) setTimeout(() => { this.scrollToSlide(data) }, 500)
			}
		}
	}
</script>
