<template>
	<div id="postlive" class="h-100-header">
		<b-container fluid class="h-100  px-0 pl-3" v-if="category && assets">
			<b-row class="h-100">
				<b-col cols="12" xl="4" class="h-50-pre">
					<b-row class="pl-1 pl-lg-7 h-100 pb-3 pt-4 pt-xl-0 d-flex justify-content-start">
						<b-col cols="12" class="">
							<!-- <h2 class="pt-2">Skanska Live</h2> -->
							<h1 class="pt-01" v-if="category.title" v-html="makeBr(category.title)" />
						</b-col>
						<b-col cols="12" md="8" xl="12" class="text-dark pl-5 pr-8 d-flex flex-wrap align-content-start align-content-md-end align-content-xl-start min-h-16">
							<div class="pb-5 pt-5 w-100">
								<p class="big m-0 pt-3 pb-5"  v-html="customField(lang+'description')"></p>
								<p class="big m-0 pt-3 pb-5"  v-html="customField(lang+'message')"></p>
								<hr >
								<b-row class="">
									<b-col cols="4" v-for="asset, akey in assets" :key="'a'+akey" class="px-2 pt-5">
										<router-link	:to="{ name: 'type', params: { slug: asset.asset_category_key } }"	
														:variant="channel.skin_button_color ? channel.skin_button_color : 'primary'"
														class="w-100 btn btn-filter button px-1">
											{{$t('language.'+asset.language.name) }}
										</router-link>
									</b-col>
								</b-row>
							</div>
						</b-col>
					</b-row>
				</b-col>
				<b-col cols="6" xl="4" :style="backgroundImage() ? backgroundImage() : null" class="bg-skanska-cover"></b-col>
				<b-col cols="6" xl="4" class="bg-skanska-s"></b-col>
			</b-row>
		</b-container>
	</div>
</template>
<script>
    export default {
		name: 'Category',
		data() {
			return {
				lang: 'en'
			}
		},
		computed: {
			category() {
				return this.$store.getters.category
			},
			channel() {
				return this.$store.getters.channel
			},
			assets() {
				return this.$store.getters.assets
			},
		},
		methods: {
			makeBr(text) {
				return text.replace('\n', '<br>')
			},
			backgroundImage() {
				const background = this.category && this.category.thumbnail_url 
				if (background) return 'background-image: url(' + this.category.thumbnail_url + ')'
				return null
			},
			customField(slug) {
				var value = null
				value = _.find(this.category.customfields, { slug: slug }) ? _.find(this.category.customfields, { slug: slug }).value : '';
				return value
			},
		},
		mounted() {
			this.lang = navigator.language.slice(0, 2) || navigator.userLanguage.slice(0, 2)
			this.$i18n.locale = navigator.language.slice(0, 2) || navigator.userLanguage.slice(0, 2) 
		}
    }
</script>
