<template>
	<div id="upload" v-if="customField(upload_on) && customField(upload_on).length !== 0" class="p-6 pr-8">
		<h2 v-if="customField(title)" v-html="makeBr(customField(title))" />
		<p v-if="customField(description)" v-html="makeBr(customField(description))" class="pt-5"/>
		<p class="big m-0 pt-5" v-if="customField(upload_title) && customField(upload_fields) === '1'" v-html="makeBr(customField(upload_title))" />
		<b-form class="pt-3" @submit.prevent="onSubmit" v-if="customField(upload_fields) === '1'">
			<b-row>
				<b-col cols="12" >
					<b-form-group v-for="(field, ckey) in customfields" :key="'custom'+ckey">
						<label :for="'field'+$t(field.label)">{{ $t(field.label) }}</label>
						<b-form-input 	:id="'field'+$t(field.label)"
										v-model="field.value"
										:type="field.type"
										:name="$t(field.label)">
						</b-form-input>
					</b-form-group>
				</b-col>
				<b-col cols="12" v-if="!progress">
					<b-form-group>
						<label for="upload">{{ $t('form.upload') }}</label>
						<b-form-file 	name="file"
										id="upload"
										class="dropzone"
										v-model="file"
										:state="Boolean(file)"
										:placeholder="$t('form.upload-here')"
										drop-placeholder="Drop file here...">
						</b-form-file>
					</b-form-group>
				</b-col>
				<b-col cols="12"  v-else>
					<p class="m-0 py-5">Uploading...</p>
				</b-col>
				<b-col cols="12">
					<b-form-group>
						<label for="fieldDescription">{{ $t('form.description') }}</label>
						<b-form-input 	id="questionName"
										v-model="fields.description"
										type="text"
										name="name">
						</b-form-input>
					</b-form-group>
				</b-col>
				<b-col cols="12">
					<b-form-group>
						<b-form-checkbox 	id="questionGdpr"
											:class="checkClasses()"
											v-model="fields.gdpr"
											name="questionGdpr"
											required>
							<span class="small pl-2" v-if="customField(gdpr)" v-html="makeBr(customField(gdpr))" /> 
							<font-awesome-icon icon="check" size="sm"></font-awesome-icon>
						</b-form-checkbox>
					</b-form-group>
				</b-col>
				<b-col cols="12" class="pt-4" v-if="!progress && !sent">
					<b-btn type="submit" :variant="asset.theme.skin_button_color ? asset.theme.skin_button_color : 'primary'" :class="asset.theme.skin_button_text_color ? 'text-' + asset.theme.skin_button_text_color : null" >{{ $t('form.upload') }}</b-btn>
				</b-col>
				<b-col cols="12"  v-else-if="!sent">
					<p class="m-0 py-5">Uploading...</p>
				</b-col>
				<b-col cols="12"  v-else>
					<p class="m-0 py-5 big text-primary">{{ $t(sent) }}</p>
				</b-col>
			</b-row>
		</b-form>
	</div>
</template>
<script>
const axios = require('axios')
export default {
	data() {
		return {
			file: null,
			sent: null,
			progress: null,
			upload_on: process.env.VUE_APP_UPLOADED_UPLOAD,
			gdpr: process.env.VUE_APP_UPLOADED_GDPR,
			title: process.env.VUE_APP_UPLOADED_TITLE,
			description: process.env.VUE_APP_UPLOADED_DESCRIPTION,
			upload_title: process.env.VUE_APP_UPLOADED_UPLOAD_TITLE,
			upload_fields: process.env.VUE_APP_UPLOADED_UPLOAD_FIELDS,
			fields: {
				description: null,
				gdpr: null
			},
			customfields: [
				{
					slug: process.env.VUE_APP_UPLOADED_BY,
					value: null,
					label: 'form.email',
					type: 'email',
					validate: 'email',
					visible: true,
				},
				{
					slug: process.env.VUE_APP_UPLOADED_TEAM,
					value: null,
					label: 'form.team',
					type: 'text',
					visible: true,
				},
				{
					slug: process.env.VUE_APP_UPLOADED_PARTICIPANTS,
					value: null,
					label: 'form.participants',
					type: 'text',
					visible: true,
				}
			]
		}
	},
	computed: {
		asset() {
			return this.$store.getters.asset
		},
		type() {
			return this.$store.getters.type
		}
	},
	methods: {
		makeBr(text) {
			return text.replace(/\n/g, '<br>')
		},
		customField(slug) {
			var value = null
			if (this.asset.customfieldgroups) {
				_.forEach(this.asset.customfieldgroups, group => {
					if (_.find(group.customfields, { slug: slug })) {
						value = _.find(group.customfields, { slug: slug }) ? _.find(group.customfields, { slug: slug }).value : false;
					}
				})
			}
			return value
		},
		onSubmit() {
			if (this.file) {
				let data = {
					title: this.file.name,
					description: this.fields.description,
					original_filename: this.file.name,
					folder_id: process.env.VUE_APP_UPLOADED_FOLDER_ID,
					assetable_type: this.getType(),
					customfields: this.customfields,
					api_key: this.type.api_key
				}
				this.$store.dispatch('upload', data)
					.then(data => {
						let formData = new FormData();
						formData.append('file', this.file)
						let config = {
							onUploadProgress: progressEvent => this.progress = Math.round(progressEvent.loaded / progressEvent.total * 100)
						}
						return axios.put(data.upload_url, formData.get('file'), config)
					})
					.then(() => {
						this.sent = 'form.file-sent'
					})
					.catch((err) => {
						console.log('Error', err);
						this.sent = 'Error'
					})
					.finally(() => {
						this.progress = null
						this.file = null
						this.fields.email = null,
						this.fields.description = null,
						_.forEach(this.customfields, field => {field.value = null})
						setTimeout(function () {
							this.sent = null
						}.bind(this), 2000);
					})
			}
		},
		getType() {
			let type = null;
			if (this.file.type.match(/video/)) {
				type = 'App\\AssetVideo';
			}
			else if (this.file.type.match(/audio/)) {
				type = 'App\\AssetAudio';
			}
			else if (this.file.type.match(/image/)) {
				type = 'App\\AssetImage';
			}
			else {
				type = 'App\\AssetFile';
			}
			return type;
		},
		checkClasses() {
			return [
				this.asset.theme.skin_input_background_color ? 'bg-' + this.asset.theme.skin_input_background_color : 'bg-white',
				this.asset.theme.skin_input_border_color ? 'border-' + this.asset.theme.skin_input_border_color : 'border-light',
				this.asset.theme.skin_button_color ? 'checked-bg-' + this.asset.theme.skin_button_color : 'checked-bg-dark',
				this.asset.theme.skin_button_text_color ? 'custom-text-' + this.asset.theme.skin_button_text_color : 'custom-text-light'
			]
		},
	}
}
</script>
